"use client"

import { useState, useEffect } from 'react'
import { usePathname } from 'next/navigation'

function useActivePathname() {
  const [activePathname, setActivePathname] = useState('')
  const asPath = usePathname();

  useEffect(() => {
    // Using URL().pathname to get rid of query and hash
    const currentPathname = new URL(asPath, location.href).pathname.toLowerCase()

    if (activePathname !== currentPathname) {
      setActivePathname(currentPathname)
    }
  }, [
    asPath,
    activePathname,
    setActivePathname,
  ])

  return activePathname;
}

export default useActivePathname
