'use client'
import type { SPContext } from '@hooks/use-analytics'
import { createContext } from 'react'

export type TPageContext = Extract<SPContext, { name: 'page' }>
export type TPlaceContext = Extract<SPContext, { name: 'place' }>
export type TProviderContext = Extract<SPContext, { name: 'provider' }>
export type TContentContext = Extract<SPContext, { name: 'content' }>

export const PageContext = createContext<TPageContext | null>(null)
export const BlockContext = createContext<TContentContext | null>(null)
export const ItemContext = createContext<TContentContext | null>(null)

