/**
 * analytics.tsx
 *
 * This module is used as an abstraction layer for the NextJS app router's
 * client server code-splitting.  Client side event listeners (like onClick)
 * cannot be exist inside server components.
 */
'use client'

import type { SPContext, SPEvent, TrackMerge, TrackProps } from '@hooks/use-analytics'
import useAnalytics from '@hooks/use-analytics'
import {
  BlockContext,
  ItemContext,
  PageContext,
} from '@lib/analytics'
import type { ReactElement } from 'react'
import React, { useContext, useEffect, useMemo, useRef } from 'react'

export interface AnalyticsProps {
  view?: boolean
  click?: SPEvent
  expand?: SPEvent
  collapse?: SPEvent
  contexts?: SPContext[]
  children?: ReactElement
  merge?: TrackMerge | TrackProps
}

/**
 * An intermediary wrapper for containing client side side effects
 * for use with the NextJS App Router. This wrapper will reduce
 * the amount of code shipped to the browser.
 *
 * @example
 * // For page view tracking:
 * <Analytics
 *   click={{ name: 'view', data:{} }}
 *   contexts={[{
 *      name: 'page',
 *      data: {
 *        page_type: 'article',
 *        language: 'en-US',
 *        page_entry_id: 'abc123',
 *        tags: ['awesome', 'cool'],
 *     },
 *    }]}
 * />
 *
 * // For event tracking:
 * <Analytics
 *     click={{
 *       name: 'navigation_click',
 *       data: {
 *         navigation_tree: '???',
 *         navigation_subject: link1.name,
 *         navigation_level: 1,
 *         navigation_url: link1.route,
 *       },
 *     })}
 *     contexts={[{ name: 'section', data: { section_name: 'header' } }]}>
 * </Analytics>
 * @returns
 */

function Analytics({
  view,
  click,
  expand,
  collapse,
  contexts = [],
  children,
}: Readonly<AnalyticsProps>) {
  const { track, trackPageView } = useAnalytics()
  const pageContext = useContext(PageContext)
  const blockContext = useContext(BlockContext)
  const itemContext = useContext(ItemContext)

  const contextsAppended = useMemo(() => {
    let result = contexts.slice() // copy array
    if (pageContext) result.push(pageContext)
    if (blockContext) result.push(blockContext)
    if (itemContext) result.push(itemContext)
    return result
  }, [contexts, pageContext, blockContext, itemContext])

  const oneRequired: { [key: string]: boolean | SPEvent | undefined } = {
    view,
    click,
    expand,
    collapse,
  }
  const requiredPropsSent = Object.keys(oneRequired).filter(
    (prop) => oneRequired[prop] !== undefined
  )

  // GUARDS
  if (requiredPropsSent.length !== 1) {
    throw new Error(
      'One and only one of the following props can be present: "view", "click", "expand", "collapse"'
    )
  }

  if (view && children) {
    throw new Error('If "view" prop is present, there should be no children')
  }

  // TRACK PAGE VIEW
  const pageViewed = useRef(false);

  useEffect(() => {
    if (!pageViewed.current && view) {
      trackPageView({ contexts: contextsAppended })
    }
    pageViewed.current = true;
  }, [contextsAppended, trackPageView, view])

  if (!children) return null

  const handler = (event: SPEvent) => {
    track({ event, contexts: contextsAppended })
  }

  // TRACK NON-VIEW EVENTS
  return React.cloneElement(children, {
    ...(click && { onClick: () => handler(click) }),
    ...(expand && { onClick: () => handler(expand) }),
    ...(collapse && { onClick: () => handler(collapse) }),
  })
}

export default Analytics
