'use client'

import type { ComponentPropsWithoutRef } from 'react'
import { Icon, Link } from '@shc/ui'
import Analytics from '@components/analytics'
import type { SectionContext } from '@hooks/use-analytics'

type ContactUsProps = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
  section: SectionContext['section_name']
}

const ContactUs = ({ section, ...props }: ContactUsProps) => {

  const contactUsLabel = 'Contact us'
  const contactUsLink = '/contact-us'

  const phoneLabel = '1-877-518-7264'
  const phoneLink = 'tel:+18775187264'

  return (
    <div {...props}>
      <h2 className="text-sm font-semibold text-gray-700">Need help?</h2>
      <ul>
        <li className="mt-4">
          <Analytics
            click={{
              name: 'contact_us_click',
              data: { contact_type: 'contact' },
            }}
            contexts={[
              {
                name: 'component',
                data: { component_text: contactUsLabel, component_url: contactUsLink },
              },
              { name: 'section', data: { section_name: section } },
            ]}>
            <Link
              href={contactUsLink}
              className="inline-flex flex-row items-center text-sm"
              noUnderline>
              <Icon icon="envelope" className="text-gray-700 pr-1.5" />
              <span className='text-tertiary hover:text-linkhover'>{contactUsLabel}</span>
            </Link>
          </Analytics>
        </li>
        <li className="mt-4">
          <Analytics
            click={{
              name: 'contact_us_click',
              data: { contact_type: 'call' },
            }}
            contexts={[
              {
                name: 'component',
                data: { component_text: phoneLabel, component_url: phoneLink },
              },
              { name: 'section', data: { section_name: section } },
            ]}>
            <Link
              href={phoneLink}
              className="inline-flex flex-row items-center text-sm"
              noUnderline>
              <Icon icon="phone" className="text-gray-700 pr-1.5" />
              <span className='text-tertiary hover:text-linkhover'>{phoneLabel}</span>
            </Link>
          </Analytics>
        </li>
      </ul>
    </div>
  )
}

export default ContactUs