import slugify from '@sindresorhus/slugify'
import type { IconName } from '@shc/ui'

export const getIcon = (iconName?: string) => {
  if (!iconName) {
    return null
  }

  const name = slugify(iconName)
  
  return name as IconName
}
