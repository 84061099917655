"use client"

import { Ref, forwardRef, ComponentPropsWithRef, createElement } from 'react'
import clsx from 'clsx'

export interface AnchorProps extends ComponentPropsWithRef<'a'> {
  variant?: 'primary' | 'light' | 'dark'
  underline?: boolean
  as?: 'a' | 'button'
}

const Anchor = (
  { variant = 'primary', underline = true, className, as = 'a', ...props }: AnchorProps,
  ref: Ref<HTMLAnchorElement>
) =>
  createElement(as, {
    ref,
    ...props,
    className: clsx(
      'transition-colors',
      'cursor-pointer',
      'font-bold',
      'underline-offset-1',
      'outline-primary outline-offset-1 outline-2 focus-visible:outline ring-0 ring-offset-0 rounded',
      variant === 'primary' && 'text-link outline-primary hover:text-linkhover',
      variant === 'primary' && underline && 'underline decoration-1 hover:decoration-2',
      variant === 'dark' &&
        'text-gray-700 outline-primary underline decoration-1 hover:decoration-2',
      variant === 'light' && 'text-white outline-white underline decoration-1 hover:decoration-2',
      className
    ),
  })

export default forwardRef(Anchor)
