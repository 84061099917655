import { HTMLAttributes, Ref, forwardRef, createElement } from 'react'
import clsx from 'clsx'

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  level: 1 | 2 | 3 | 4 | 5 | 6
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  ref?: Ref<HTMLHeadingElement>
}

const Heading = (
  { level, size, className = '', children, ...props }: HeadingProps,
  ref: Ref<HTMLHeadingElement>
) => {
  const headingLevel = `h${level}`
  const s = size ?? headingLevel
  return createElement(
    headingLevel,
    {
      ...props,
      ref,
      className: clsx(
        s === 'h1' && 'text-3xl md:text-4xl font-medium',
        s === 'h2' && 'text-2xl md:text-3xl font-medium',
        s === 'h3' && 'text-xl font-medium',
        s === 'h4' && 'text-xl font-semibold',
        s === 'h5' && 'text-lg font-semibold',
        s === 'h6' && 'text-base font-semibold',
        className
      ),
    },
    children
  )
}

export default forwardRef(Heading)
