'use client'

import { useState } from 'react'
import { usePathname } from 'next/navigation'
import { Alert, Button, Icon, Typography } from '@shc/ui'

interface AlertSpoofingProps {
  spoofedName: any
  user: string
}

const AlertSpoofing = ({
  spoofedName,
  user,
}: AlertSpoofingProps) => {
  const [isSpoofing, setIsSpoofing] = useState(true)
  const pathname = usePathname()

  return (
    user?.access_token && isSpoofing && pathname.includes('/providers') && (
      <div className="container mx-auto my-3">
        <Alert
          className="items-center justify-between py-2"
          severity="error">
          <div />{/* Pseudo-column to keep icon and name centered */}
          <Typography>
            <Icon
              className="mr-2"
              icon="eye"
            />Spoofing {spoofedName}
          </Typography>
          <div>
            <Button
              aria-label="Unspoof"
              color="light"
              onClick={() => setIsSpoofing(false)}
              size="xs"
              width="auto">
              Unspoof
            </Button>
          </div>
        </Alert>
      </div>
    )
  )
}

export default AlertSpoofing
