import { HTMLAttributes, Ref, forwardRef, createElement } from 'react'
import clsx from 'clsx'

export interface ContainerProps extends HTMLAttributes<HTMLElement> {
  as?: 'div' | 'section' | 'article' | 'aside' | 'header' | 'footer' | 'nav' | 'main'
  ref?: React.Ref<HTMLElement>
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}

const containerBreakpointMap = {
  'sm': 'sm:container sm:mx-auto',
  'md': 'md:container md:mx-auto',
  'lg': 'lg:container lg:mx-auto',
  'xl': 'xl:container xl:mx-auto',
  '2xl': '2xl:container 2xl:mx-auto',
}

const Container = (
  { as = 'div', breakpoint, id = '', className, children, ...props }: ContainerProps,
  ref: Ref<HTMLElement>
) =>
  createElement(
    as,
    {
      ref,
      id: id !== '' ? id : undefined,
      ...props,
      className: clsx(
        !breakpoint && 'container mx-auto',
        breakpoint && containerBreakpointMap[breakpoint],
        className
      ),
    },
    children
  )

export default forwardRef(Container)
